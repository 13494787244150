import React from "react"
import { SectionTitle } from "../components/styled"
import WrapperRight from "../components/WrapperRight"
import { Link } from "gatsby"
import Contact from "../components/Contact"
import ContactForm from "../components/ContactForm"
import { Seo } from "../components/seo"

const SkypeWin = () => {
  return (

      <section className="container skype">
        <div className="row">
          <div className="col-lg-9 col-lg-9">
            <div className="skype skype__flow">
              <SectionTitle>
                Skype(スカイプ)の使用方法について（WINDOWSの場合）
              </SectionTitle>
              <hr className="u-line-bottom" />
              <p className="skype__text-block">
                WINDOWSをご利用皆様へ、Skypeの使用方法について説明いたします。ダウンロードの仕方や使用方法、面談の際に必要なSkypeIDなどの確認方法をお確かめください。MACをお使いの場合は
                <Link href="/skype_mac">
                  Skypeの使用方法について（MACの場合）
                </Link>
                をご覧ください。
              </p>
              <div className="skype-menu">
                <ul className="skype-menu-list">
                  <li>
                    <a href="#section1">
                      <b>Skypeとは</b>
                    </a>
                  </li>
                  <li>
                    <a href="#section2">
                      <b>当社でのSkype利用について</b>
                    </a>
                    <ol>
                      <li>
                        <a href="#section2-1">面談までにしていただくこと</a>
                      </li>
                      <li>
                        <a href="#section2-2">
                          SkypeID（Skype名）やSkype表示名の確認方法
                        </a>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <a href="#section3">
                      <b>Skypeのダウンロードから通話までの流れ</b>
                    </a>
                    <ol>
                      <li>
                        <a href="#section3-1">ダウンロードとインストール</a>
                      </li>
                      <li>
                        <a href="#section3-2">アカウントを取得</a>
                        <ul>
                          <li>
                            <a href="#section3-2-1">
                              携帯の電話番号を使用し作成する場合
                            </a>
                          </li>
                          <li>
                            <a href="#section3-2-2">
                              メールアドレスを使用し作成する場合
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <a href="#section3-3">サインイン</a>
                      </li>
                      <li>
                        <a href="#section3-4">通話音声・カメラチェック</a>
                      </li>
                      <li>
                        <a href="#section3-5">コンタクト追加</a>
                      </li>
                      <li>
                        <a href="#section3-6">通話までの流れ</a>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <a href="#section4">
                      <b>お問い合わせ先</b>
                    </a>
                  </li>
                </ul>
              </div>
              <section id="section1">
                <h2 className="skype__flow">Skypeとは</h2>
                <img
                  src="https://res.cloudinary.com/dkskzhwoe/image/upload/v1624478480/skype/skype_win-1_eoyyvn.webp"
                  className="img-skype-half"
                  alt="skype_win"
                />
                <p>
                  Skypeはマイクロソフト社が提供するサービスで、インターネットを通じて無料で通話・ビデオ通話ができるソフトウェアです。
                  <br></br>
                  Skypeのアカウントを持っている者同士なら、いつでも無料でビデオ通話・音声通話が利用できます。パソコンをはじめ、スマートフォンやタブレットでも利用ができ、無料でビデオ通話ができることから広く利用されています。
                </p>
                <br></br>
                <p>
                  なお、パソコンにマイクや内蔵カメラ（内側）がない場合は、別途ヘッドセットやwebカメラを購入する必要があります。スマートフォンやタブレットでも内蔵カメラ（内側）がない場合は、ビデオ通話が難しくなります。
                </p>
              </section>
              <section id="section2">
                <h2 className="skype__flow">当社でのSkype利用について</h2>
                <p className="skype__text-block">
                  当社での面談はSkypeを使用しています。日本と海外とのビデオ通話も無料で行えるので、応募者も安心してご利用いただけます。
                  <br></br>
                  また、まだSkypeをお持ちでない方は、今後当社との面談のためだけではなく、企業との面接の際もSkypeが必要となるケースが多いため、このタイミングでのダウンロードをしていただく必要があります。
                </p>
                <section id="section2-1">
                  <h3>面談までにしていただくこと</h3>
                  <h4>１、アカウント情報をお伝えください。</h4>
                  <p>
                    当社からまずSkypeでの「コンタクト・リクエストの申請」を行いますので、Skypeアカウント情報をお知らせいただきます。
                  </p>
                  <ul className="skype__list">
                    <li className="none">[アカウント情報]</li>
                    <li>SkypeID（Skype名）</li>
                    <li>Skype表示名</li>
                  </ul>
                  <p>上記２点を担当者にお知らせください。</p>
                  <h4>２、追加申請を「承諾」してください。</h4>
                  <p>
                    アカウント情報をお知らせいただいた後、当社のSkypeからコンタクトの追加申請をお送りします。追加申請が届きますので、承諾をお願いします。
                    <br></br>
                    （承諾がないと面談前に状況確認の為のチャットができません。）
                  </p>
                </section>
                <section id="section2-2">
                  <h3>SkypeID（Skype名）やSkype表示名の確認方法</h3>
                  <h4 className="h4--vline">パソコンでの確認方法</h4>
                  <ol>
                    <li>
                      画面左下のWindowsマークをクリックし、Skypeを選択します。＊Windows10にはSkypeが標準装備されています。
                      <img
                        className="img-skype"
                        src="https://res.cloudinary.com/dkskzhwoe/image/upload/v1624478480/skype/skype_win-2-2-1-1_cylwtu.webp"
                        alt="skype_win2"
                      />
                    </li>
                    <li>
                      画面左上にあるマークをクリックします。（プロフィール写真が表示されている箇所です。）
                      <img
                        className="img-skype"
                        src="https://res.cloudinary.com/dkskzhwoe/image/upload/v1624478480/skype/skype_win-2-2-1-2_sxdehz.webp"
                        alt="skype_win2-2"
                      />
                    </li>
                    <li>
                      トップに表示されている名前がSkype表示名です。その下のlive:の右部分がSkypeIDです。
                      <img
                        className="img-skype"
                        src="https://res.cloudinary.com/dkskzhwoe/image/upload/v1624478480/skype/skype_win-2-2-1-3_zgazhh.webp"
                        alt="skype_win2-3"
                      />
                    </li>
                  </ol>
                  <h4 className="h4--vline">Web上での確認方法</h4>
                  <ol>
                    <li>web上でサインインし、「マイアカウント」を開きます。</li>
                    <li>
                      すぐにSkype表示名が確認できます。
                      <img
                        className="img-skype"
                        src="https://res.cloudinary.com/dkskzhwoe/image/upload/v1624478480/skype/skype_win-2-2-2-2_g62frn.webp"
                        alt="skype_win2-2-2"
                      />
                    </li>
                    <li>
                      次に画面右上のマークの左横をクリックし、「Skypeをオンラインで使用する」を選択します。
                      <img
                        className="img-skype"
                        src="https://res.cloudinary.com/dkskzhwoe/image/upload/v1624478480/skype/skype_win-2-2-2-3_r5nx0f.webp"
                        alt="skype_win2-2-3"
                      />
                    </li>
                    <li>
                      SkypeIDが表示されます。
                      <img
                        className="img-skype"
                        src="https://res.cloudinary.com/dkskzhwoe/image/upload/v1624478481/skype/skype_win-2-2-2-4_zxeh67.webp"
                        alt="skype_win2-2-4"
                      />
                    </li>
                  </ol>
                </section>
              </section>
              <section id="section3">
                <h2>Skypeのダウンロードから通話までの流れ</h2>
                <p>
                  Skypeを利用するには端末でのアプリのダウンロードや設定、利用登録が必要になります。順に説明してまいります。
                </p>
                <section id="section3-1">
                  <h3>１、ダウンロードとインストール</h3>
                  <p className="skype__text-block">
                    Skype公式ウェブサイト（
                    <a href="https://www.skype.com/ja/get-skype/">
                      www.skype.com
                    </a>
                    ）からアプリケーションをダウンロードします。<br></br>
                    パソコン、携帯電話、タブレットから選べます。<br></br>
                    ＊基本的には、自動的にアクセスに使用している端末の情報を読み取って、MacかWindowsなどを選択してくれます。
                    <br></br>
                    ＊携帯電話やタブレットの場合は、アプリをダウンロードするのと同じ要領で進みます。
                    <br></br>
                    MACとWindowsの場合で方法が少し異なります。現在ご覧のページはWINDOWSの場合の使い方になっております。
                    <a href="/skype_mac">MACの場合はこちら</a>をご覧ください。
                  </p>
                  <h4 className="h4--vline">パソコン（Windows）の場合</h4>
                  <ol>
                    <li>
                      Windows10にはSkypeが標準装備されています。画面左下のWindowsマークをクリックし、Skypeを選択します。
                      <img
                        className="img-skype"
                        src="https://res.cloudinary.com/dkskzhwoe/image/upload/v1624478481/skype/skype_win-3-1-1-1_ab8nio.webp"
                        alt="skype_win3-1"
                      />
                    </li>
                  </ol>
                </section>
                <section id="section3-2">
                  <h3>２、アカウントを取得</h3>
                  <p>
                    Skypeを開くと、ログイン画面が表示されます。アカウントを持っている人は「サインイン」、まだ持っていない人は「アカウントを作成」を選びます。
                  </p>
                  <h4 className="h4--vline">Microsoftでのアカウント作成手順</h4>
                  <p className="skype__text-block2">
                    アカウントの作成には、２通りの選択があります。<br></br>
                    <a href="#section3-2-1">
                      携帯の電話番号を使用し作成する場合
                    </a>
                    と
                    <a href="#section3-2-2">
                      メールアドレスを使用し作成する場合
                    </a>
                    です。順に説明します。
                  </p>
                  <section id="section3-2-1">
                    <h5>携帯の電話番号を使用し作成する場合</h5>
                    <ol>
                      <li>
                        「アカウントを作成」を選んだら、まず電話番号を入力します。
                        <img
                          className="img-skype"
                          src="https://res.cloudinary.com/dkskzhwoe/image/upload/v1624478481/skype/skype_win-3-2-1-1_tppm26.webp"
                          alt="skype_win3-2-1"
                        />
                      </li>
                      <li>
                        次に氏名を入力します
                        <img
                          className="img-skype"
                          src="https://res.cloudinary.com/dkskzhwoe/image/upload/v1624478481/skype/skype_win-3-2-1-2_ewwo3t.webp"
                          alt="skype_win3-2-2"
                        />
                      </li>
                      <li>
                        先ほど入力した電話番号にメールで<b>４桁のコード</b>
                        が送られますので、そちらを確認し、入力します。
                        <img
                          className="img-skype"
                          src="https://res.cloudinary.com/dkskzhwoe/image/upload/v1624478481/skype/skype_win-3-2-1-3_cyqthc.webp"
                          alt="skype_win3-2-3"
                        />
                        携帯ではこのようなSMSメールが届きます
                        <img
                          className="img-skype"
                          src="https://res.cloudinary.com/dkskzhwoe/image/upload/v1624478481/skype/skype_win-3-2-1-3_1_oofogp.webp"
                          alt="skype_win3-2-4"
                        />
                      </li>
                      <li>
                        電話番号の検証を通過すると、アカウント取得完了です。
                        <br></br>
                        「サウンド＆カメラのチェック」があり、カメラチェックなどはこの時に終えている方がいいでしょう。
                      </li>
                      <li>
                        設定を終えると、ご自身のアカウントでログインした状態となり、ホーム画面になります。
                        <img
                          className="img-skype"
                          src="https://res.cloudinary.com/dkskzhwoe/image/upload/v1624478481/skype/skype_win-3-2-1-5_ryxlnn.webp"
                          alt="skype_win3-2-1-5"
                        />
                      </li>
                    </ol>
                  </section>
                  <section id="section3-2-2">
                    <h5>メールアドレスを使用し作成する場合</h5>
                    <ol>
                      <li>
                        「アカウントを作成」を選んだら、メールアドレスを入力します。もしくは「新しいメールアドレスを取得」をクリックし、新しいアドレスを取得します。
                      </li>
                      <li>
                        メールアドレスの入力欄が表示されますので、お持ちのメールアドレスを入力し、次へ。
                        <img
                          className="img-skype"
                          src="https://res.cloudinary.com/dkskzhwoe/image/upload/v1624478482/skype/skype_win-3-2-2-2_f7ez27.webp"
                          alt="skype_win3-2-2-2"
                        />
                      </li>
                      <li>
                        サインイン時に必要になるパスワードを設定します。
                        <img
                          className="img-skype"
                          src="https://res.cloudinary.com/dkskzhwoe/image/upload/v1624478482/skype/skype_win-3-2-2-3_tqhaz5.webp"
                          alt="skype_win3-2-2-3"
                        />
                      </li>
                      <li>
                        次に氏名を入力します
                        <img
                          className="img-skype"
                          src="https://res.cloudinary.com/dkskzhwoe/image/upload/v1624478482/skype/skype_win-3-2-2-4_av7thb.webp"
                          alt="skype_win3-2-2-4"
                        />
                      </li>
                      <li>
                        セキュリティ情報の追加として、生年月日を入力します。
                        <img
                          className="img-skype"
                          src="https://res.cloudinary.com/dkskzhwoe/image/upload/v1624478482/skype/skype_win-3-2-2-5_dnpxwv.webp"
                          alt="skype_win3-2-2-5"
                        />
                        「サウンド＆カメラのチェック」があり、カメラチェックなどはこの時に終えている方がいいでしょう。
                      </li>
                      <li>
                        設定を終えると、ご自身のアカウントでログインした状態となり、ホーム画面になります。
                        <img
                          className="img-skype"
                          src="https://res.cloudinary.com/dkskzhwoe/image/upload/v1624478483/skype/skype_win-3-2-2-6_v168o6.webp"
                          alt="skype_win3-2-2-6"
                        />
                      </li>
                    </ol>
                  </section>
                </section>
                <section id="section3-3">
                  <h3>３、サインイン</h3>
                  <p>
                    Skypeでのサインイン（ログイン）方法です。<br></br>
                    一度サインインすれば、サインアウトしない限りは通常サインイン状態のままになります。
                  </p>
                  <ol>
                    <li>
                      まず、サインインのボタンを押すと「メール、電話番号、またはSkype」を入力してくださいと表示されます。先程登録した電話番号かメールアドレスを入力してサインインします。生年月日を聞かれる項目では入力をしてください。
                      <img
                        className="img-skype"
                        src="https://res.cloudinary.com/dkskzhwoe/image/upload/v1624478482/skype/skype_win-3-3-1-1_lpxlqg.webp"
                        alt="skype_win3-3-1-1"
                      />
                    </li>
                  </ol>
                </section>
                <section id="section3-4">
                  <h3>４、通話音声・カメラチェック</h3>
                  <h4 className="h4--vline">音声チェック／テスト通話</h4>
                  <p>
                    ご利用のの端末できちんとマイクやスピーカーが使えているかの確認です。必ず事前にチェックしましょう。
                  </p>
                  <ol>
                    <li>
                      検索で「echo」と入力します。
                      <img
                        className="img-skype"
                        src="https://res.cloudinary.com/dkskzhwoe/image/upload/v1624478482/skype/skype_win-3-4-1-1_x0jmag.webp"
                        alt="skype_win3-4-1-1"
                      />
                    </li>
                    <li>
                      Echo / Sound Test
                      Service.を選択し、右上の受話器のマークをクリックして電話をかけます。
                      <img
                        className="img-skype"
                        src="https://res.cloudinary.com/dkskzhwoe/image/upload/v1624478482/skype/skype_win-3-4-1-2_vk6jbm.webp"
                        alt="skype_win3-3-1-2"
                      />
                    </li>
                    <li>
                      マイクの使用の許可が求められますので、許可します。設定の際、すでに許可を選択している場合は通話が始まります。
                      <img
                        className="img-skype"
                        src="https://res.cloudinary.com/dkskzhwoe/image/upload/v1624478483/skype/skype_win-3-4-1-3_rhqsfb.webp"
                        alt="skype_win3-3-1-3"
                      />
                    </li>
                    <li>
                      説明音声が聞こえ、「今から１０秒間話してください」と指示されますので、10秒間テストで話します。
                      <img
                        className="img-skype"
                        src="https://res.cloudinary.com/dkskzhwoe/image/upload/v1624478483/skype/skype_win-3-4-1-4_mocfhe.webp"
                        alt="skype_win3-3-1-4"
                      />
                    </li>
                    <li>
                      その後、録音された自分の声が再生されます。その音声が相手側に聞こえる音声です。
                    </li>
                    <li>何も問題がなければ、テスト完了です</li>
                  </ol>
                  <h4 className="h4--vline">カメラチェック</h4>
                  <p>
                    こちらはカメラがきちんと使えるかの確認です。すぐに確認ができるので、音声チェックの際に一緒に行いましょう。
                  </p>
                  <ol>
                    <li>
                      Web
                      カメラがきちんと動作しているかどうかを確認します。Windows
                      デスクトップの場合は「ツール」、「オプション」、「ビデオ設定」の順に選択します。
                    </li>
                    <li>
                      ビデオが表示される場合、Skype
                      でカメラは動作し、使用できる状態です。
                    </li>
                  </ol>
                </section>
                <section id="section3-5">
                  <h3>５、コンタクト追加</h3>
                  <p>
                    Skype上の連絡先に通話をしたいコンタクト先が登録されていなければ、通話をすることができません。
                    <br></br>
                    LINEアプリで言う「友だち登録」を行います。まずどちらかがコンタクト申請を送り、相手が承諾をすると通話ができるようになります。
                    <br></br>
                    面談前に当社からコンタクト申請をさせていただきますので、今回は「コンタクト承諾」の方法をご案内します。
                  </p>
                  <h4 className="h4--vline">コンタクト・リクエストの承諾</h4>
                  <p className="skype__text-block">
                    当社が申請したコンタクトリクエストを承諾する方法についてです。
                    <br></br>
                    *この前に当社からご自身のSkype名をお伺いしますので、下記の
                    <a href="#section2-2">▼Skype名などの確認方法</a>
                    もご確認ください。
                  </p>
                  <ol>
                    <li>
                      コンタクト申請を受けると、左サイドのバーにメッセージが表示されるので、クリックします。
                      <img
                        className="img-skype"
                        src="https://res.cloudinary.com/dkskzhwoe/image/upload/v1624478483/skype/skype_win-3-5-1-1_ylx1zd.webp"
                        alt="skype_win3-5-1-1"
                      />
                    </li>
                    <li>
                      そうすると、「〇〇さんから連絡先追加のリクエストが来ています」というメッセージと、承認する・拒否する・ブロックするのボタンが表示されますのでここで、承諾ボタンを押します。
                      <img
                        className="img-skype"
                        src="https://res.cloudinary.com/dkskzhwoe/image/upload/v1624478483/skype/skype_win-3-5-1-2_edqocm.webp"
                        alt="skype_win3-5-1-2"
                      />
                    </li>
                    <li>
                      連絡先リストに追加されたことを確認します。
                      <img
                        className="img-skype"
                        src="https://res.cloudinary.com/dkskzhwoe/image/upload/v1624478483/skype/skype_win-3-5-1-3_nfljar.webp"
                        alt="skype_win3-5-1-3"
                      />
                    </li>
                  </ol>
                </section>
                <section id="section3-6">
                  <h3>６、通話までの流れ</h3>
                  <ol>
                    <li>
                      通話はもちろん、メッセージを送ることもできます。
                      <img
                        className="img-skype"
                        src="https://res.cloudinary.com/dkskzhwoe/image/upload/v1624479604/skype/skype_win-3-6-1-1_fsqf8k.webp"
                        alt="skype_win3-6-1-1"
                      />
                    </li>
                    <li>
                      相手から通話がかかってくると、応答ボタンがでてきます。
                      <br></br>
                      「音声通話で応答」もしくは「ビデオ通話で応答」をクリックすると、通話が開始されます。
                      <img
                        className="img-skype"
                        src="https://res.cloudinary.com/dkskzhwoe/image/upload/v1624478483/skype/skype_win-3-6-1-2_zgl3km.webp"
                        alt="skype_win3-6-1-2"
                      />
                    </li>
                  </ol>
                </section>
              </section>
              <section id="section4">
                <h2>お問い合わせ先</h2>
                <p>
                  当社キャリアコーディネーターがご相談を承ります。お気兼ねなく下記フォームからお問い合わせ下さい。
                </p>
                <p className="skype__text-block">
                  最新の求人情報は<a href="/jobs-listing">こちら</a>
                </p>
                <p className="skype__text-block">
                  電話番号：+52-449-107-5308 <br></br>
                  メールアドレス：
                  <a
                    href="mailto:quick@919mexico.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    quick@919mexico.com
                  </a>
                </p>
                <h4 className="h4--vline">お問い合わせフォーム</h4>
                <ContactForm />
              </section>
            </div>
            <Contact lang={"ja"} />
          </div>
          <WrapperRight />
        </div>
      </section>

  )
}

export default SkypeWin


export const Head = () => {
  return (
    <Seo
      title="Skype(スカイプ)の使用方法について（WINDOWSの場合）| メキシコの求人・転職・就職情報【QUICK GLOBAL MEXICO】"
      description="メキシコの求人情報の掲載、就職・転職サポートを行う転職エージェントサービス。メキシコ国内の転職だけでなく、日本からの転職もサポート。"
      url={`https://www.919mexico.com/skype_win`}      
    />
  )
}
